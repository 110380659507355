#project{

    &>div{
        $primeColor :var(--prime-color);
        $secondColor :var(--second-color);
        $opacColor-33 :var(--opac-color-33);
        $opacColor-50 :var(--opac-color-50);
        $opacColor-77 :var(--opac-color-77);
        animation: bounceInUp;
        animation-delay: 0.1s;
        animation-duration: 2s;
        animation-fill-mode: both;
        

        padding-top: 4%;
        position: relative;

        &>div{
            min-height: 264.5px;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            background-color: $primeColor;
            border-radius: 10px;
            border: 1px;
            border-style: solid;
            color: $opacColor-33;
            margin: 20px;
            padding: 16px;
            @media only screen and (max-width: 900px){
                margin-left: 0px;
                margin-right: 0px;
            }
            &>div:nth-of-type(1){
                border-color: $opacColor-50;
                background-color: #ffffff;
                width: 50px;
                height: 50px;
                position: absolute;
                right: 20%;
                top: 10%;
                border-radius: 90px;
                border-width: 1px;
                border-style: solid;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                will-change: transform;
                @media only screen and (max-width: 1200px){
                    top: 6%;
                }
                @media only screen and (max-width: 900px){
                    top: 1%;
                }
                &>img{
                    height: inherit;
                    will-change: transform;
                    border-radius: 100%;
                }
            }
            &>div:nth-of-type(2){
                &>div{
                    &>span{
                        max-width: 250px;
                        line-height: 1.1;
                        padding-bottom: 5px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: auto;

                        @media only screen and (max-width: 900px){
                            max-width: 190px;
                        }

                        &>strong{
                            word-break: keep-all;
                            color: $secondColor;
                        }

                        &:hover > .hoverEffect{
                            $movement: calc(#{var(--element-width)} * -1);
                            margin-left: $movement;
                            animation: hoverSlide infinite linear;
                            animation-duration: var(--element-width-num);
                            animation-fill-mode: forwards;
                            
                            

                            @keyframes hoverSlide {
                                0% {
                                    margin-left: 0;
                                }
                                10%{
                                    margin-left: $movement;
                                }
                                100% {
                                    margin-left: $movement;
                                }
                            }
                        }
                    }
                    &>p{
                        font-weight: 200;
                        color: $secondColor;
                    }
                }
            }
            &>div:nth-of-type(3){
                padding-top: 0px;
                &>p{
                    margin-bottom: 0px;
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: $base-font-Color;
                }
                // stepper - Root
                &>div{
                    margin-top: -2px;
                    &>div{
                        // stepLabel - Root
                        &>span{
                            fill: $primeColor;
                        // stepIcon - container
                            &>span{
                                z-index: 1;
                                &>span{
                                    &>p{
                                        width: auto;
                                        color: $secondColor;
                                        font-size: 0.8rem;
                                        background-color: #ffffff;
                                        border: 1px solid;
                                        border-radius: 90px;
                                        visibility: visible;
                                    }
                                }
                                &>div{
                                    &>.default_Icon > circle {
                                        fill: $base-light-grey;
                                    }
                                    &>.active_Icon{
                                        padding-top: 7px;
                                        path{
                                            fill: $secondColor;
                                            stroke: $secondColor;
                                        }
                                    }
                                    &>.complete_Icon{
                                        padding-top: 2px;
                                        g{
                                            fill: $secondColor;
                                            stroke: $secondColor;
                                        }
                                    }
                                    &>.color_default_Icon circle{
                                        fill: $opacColor-77;
                                    }

                                }
                            }
                            &>span:nth-of-type(2){
                                width: 140%;
                                margin-top: -7px;
                                &>span{
                                    margin-top: 0;
                                }
                            }
                        }
                        // connector - root
                        &>div{
                            left: -50%;
                            right: 50%;
                            &>span{
                                border-style: none;
                                height: 4px;
                                border-radius: 90px;
                                background-color: #ffffff;
                                &>span{
                                    &>div{
                                    
                                    }
                                }                 
                            }
                            
                        
                        }
                        &>.Mui-disabled .Mui-active{
                                color: $primeColor;
                                border: 2px solid;
                                border-radius: 20px;
                                border-color: $secondColor;
                            }

                            &>.Mui-active{
                                &:nth-of-type(1)>.MuiStepConnector-line{
                                    background-color: #ffffff;
                                }
                                &>.MuiStepConnector-line{
                                    background-color: $opacColor-33;
                                }
                            }

                            &>.Mui-completed{
                                color: $secondColor;
                                &>.MuiStepConnector-line{
                                    background-color: $opacColor-33;
                                }
                            }
                    }
                }
            }
            &>hr{
                border-color: inherit;
                flex-grow: 1;
            }
            &>div:nth-of-type(4){
& > div{
    justify-content: center;
    padding-top: 5px;
    & > div{
        display: flex;
        justify-content: flex-start;
    }
    & > div:nth-of-type(1){
        & > span{
            background-color: $primeColor;
            border-radius: 90px;
            & > #ic_message{
                & > line, path:nth-of-type(2){
                    stroke: $secondColor;
                }
            }
        }
    }
    & > div:nth-of-type(2){
        justify-content: flex-end;
        & > button {
            border-radius: 90px;
            border: 1px solid;
            width: 40%;
            color: $secondColor;
            height: 60%;
            font-size: 0.75rem;
            min-width: 93.38px;
            &:active {
                transform: scale(0.9);
                transition: all 0.2s ease-out;
            }
            &:hover{
                background-color: $secondColor;
            }

            &:hover > span {
                color: $base-Color;
            }
            & > span {
                text-transform: capitalize;
            }
        }
    }
}
            }
        }
    }
}

#accordionContainer{
    box-shadow: none;
}

#archivedTitle{
    font-family: $primary-Font;
    color: $base-font-Color;
    font-weight: 700;
    padding-left: 24px;
    font-size: 1.3rem;
}

