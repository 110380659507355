#mainContainer{
    &>div{
    &>div{
        background: #ffffff;
        border-radius: 10px;
        &>div{
            padding: 32px;
            margin-bottom: 16px;
            &>#notifBar{
                justify-content: center;
                &>div:nth-of-type(1){
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &>div{
                        box-shadow: none;
                        background-color: $base-Color;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid;
                        border-color: $base-light-grey;
                        &>div{
                            &>div:nth-of-type(1){
                                display: block;
                                align-self: center;
                                &>span{
                                    display: block;
                                    max-height: 54px;
                                    width: auto;
                                    height: auto;
                                }
                            }
                            &>div:nth-of-type(2){
                                &>p{
                                    color: $base-font-Color;
                                }
                                &>p:nth-of-type(1){
                                    font-weight: 600;
                                }
                                &>p:nth-of-type(2){
                                    font-weight: 200;
                                    font-size: 00.875rem;
                                }
                            }
                            &>div:nth-of-type(3){
                                align-self: unset;
                                &>div{
                                    margin-top: 5px;
                                    margin-right: 8px;
                                    margin-bottom: 5px;
                                    &>button{
                                        padding: 6px 12px;
                                        border-radius: 90px;
                                        color: $case-Green;
                                        background-color: rgba($case-Green, 0.2);
                                        border: 1px solid;
                                        font-size: 0.75rem;
                                        @media only screen and (max-width: 900px){
                                            min-width: unset;
                                            line-height: unset;
                                            border-radius: 100%;
                                            padding: 3px 8px;
                                        }
                                        &:hover{
                                            background-color: $case-Green;
                                            transition: all 0.2s ease-out;
                                            &>span{
                                                color: $base-Color;
                                            }
                                            &:active{
                                                transform: scale(0.9);
                                                transition: all 0.2s ease-out;
                                            }
                                        }
                                        &>span{
                                            text-transform: capitalize;
                                            font-size: 00.875rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &>div:nth-of-type(2){
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    &>span{
                        &>button{
                            padding: 0px;
                            border-radius: 90px;
                            :active{
                                transform: scale(0.9);
                                transition: all 0.2s ease-out;
                            }
                            &>span{
                                &>span{
                                    max-height: 42px;
                                    width: auto;
                                    height: auto;
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}