.react-kanban-board {
    padding: 5px;
    width: 100%;
    display: grid !important;
    align-items: inherit !important
}

.react-kanban-column {
  padding: 15px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: 5px;
  border-left: 2px solid #FAFAFA;
  border-right: 2px solid #FAFAFA;
  width: 16%;
  max-width: 20%;
}
