#navBar{
    &>header{
        background: transparent;
        box-shadow: none;
        position: static;
        &>div{
            width: 100%;
            margin: auto;
            padding-right: 0px;
            padding-left: 0px;
            &>div{
                justify-content: flex-start;
                flex-direction: row;
                &>div:nth-of-type(1){
                    display: flex;
                    justify-content: flex-start;
                    &>img{
                        height: 64px;
                    }
                }
                &>div:nth-of-type(2){
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-basis: auto;
                    max-width: none;
                    &>a{
                        color: $base-font-Color;
                        width: 67px;
                        padding: 6px 12px;
                        font-family: 'Quicksand';
                        font-weight: 600;
                        font-size: 0.875rem;
                        margin-right: 15px;
                        border: none;
                        background-color: unset;
                        cursor: pointer;
                        position: relative;
                        text-decoration: none;
                        text-align: center;
                        &::before{
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            transform: scale(0);
                            left: 0;
                            top: -1px;
                            background-color: rgba($primary-Blue, 0.1);
                            border-radius: 50px;
                            border-style: solid;
                            border-width: 1px;
                            border-color: $primary-Blue;
                            opacity: 0;
                        }
                        &:hover{
                            color: $primary-Blue;
                        }
                        &:hover::before{
                            transform: scale(1);
                            opacity: 1;
                            transition: all 0.2s ease-out;
                        }

                        &:active{
                            transform: scale(0.9);
                            transition: all 0.2s ease-out;
                        }

                    &.active_link{
                        color: $primary-Blue;
                        &::before{
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    }
                }
                &>div:nth-of-type(3){
                    display: flex;
                    justify-content: flex-end;
                    flex-grow: unset;
                    max-width: unset;
                    margin-left: auto;
                    &>div:nth-of-type(1){
                        flex-wrap: nowrap;
                        width: auto;
                        padding-right: 6px;
                        &>div{
                            display: flex;
                            justify-content: flex-end;
                            &>p{
                                font-family: $primary-Font;
                                font-size: 00.875rem;
                                font-weight: 600;
                                color: $base-font-Color;
                                line-height: 1.4;
                            }
                            &:nth-of-type(2){
                                &>p{
                                    font-weight: 200;
                                }
                            }
                        }

                    }

                    &>button:nth-of-type(1){
                        margin-right: 10px;
                        cursor: unset;
                        &:hover{
                            background-color: unset;
                        }
                    }

                    &>button:nth-of-type(2){

                        position: relative;
                        transition: all 0.2s ease;

                        &:hover{
                            background-color: unset;
                        }
                        
                        &:hover>span>svg>path{
                            color: $primary-Blue;
                            transition: all 0.2s ease;
                        }
                        
                    }

                    &>#menuDropdown{
                        position: relative;
                        left: unset!important;
                        top: 78px!important;
                        right: -2px;
                        transform: unset!important;
                        overflow: unset;
                        z-index: 999;
                        &>div{
                            border-radius: 10px;
                            box-shadow: unset;
                            -webkit-filter: drop-shadow(0px 0px 5px rgba( $base-font-Color, 0.5));
                            &>ul{
                                & > .dropDownBlob{
                                    position: absolute;
                                    right: 0px;
                                    top: -11px;
                                    margin: auto;
                                }
                                &>li{
                                    color: $base-font-Color;
                                    font-family: $primary-Font;
                                    font-weight: 400;
                                    padding-right: 80px;
                                    &:hover{
                                        color: $primary-Blue;
                                        background-color: rgba($primary-Blue, 0.1);
                                        transition: all 0.2s ease;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }



    //RESPONVIVE

    .mobileContainer{
        justify-content: space-between;
        padding-bottom: 10px;
        align-items: center;
    }

    .hamburgerContainer{
        color: $base-font-Color;
    }

    .mobileOddesseyContainer{
        & .mobileOddesseyLogo {
            height: 50px;
        }
    }

    .mobileAvatar{
        height: 40px;
        width: 40px;
    }


}

.mobileDrawerContainer{
    background-color: $base-Color!important;

    & hr {
        border-color: rgba( $base-font-Color, 0.1);
    }
}

.mobileDrawerUserContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    padding-bottom: 12px!important;
    & p {
        font-family: $primary-Font;
        color: $base-font-Color;
        font-weight: 200;
        font-size: 0.875rem;
    }

    & p.userName{
        font-weight: 500;
    }
}

.mobileNavListContainer{
    background-color: #ffffff;
}

.mobileNavItemsContainer{
    padding-top: 12px!important;
    padding-bottom: 12px!important;
    & > a{
        color: $base-font-Color;
        text-decoration: none;

        &.active_link{
            color: $primary-Blue;
            font-weight: 700;
        }
    }
}

.mobileExtraNavItemsContainer{
    li{
        font-family: $primary-Font;
        color: $base-font-Color;
    }
}