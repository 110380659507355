$base-Color: #fafafa;

$base-light-grey: #f0f0f0;
$base-hover-grey: #e6e6e6;

$base-font-Color: #848484; 

$primary-Blue: #3155A4;
$primary-orange: #F08263;

$case-Green: #25CAAC;