#board{
    background-color: #ffffff;
    border-radius: 10px;
    
        & .kanbanNavContainer{
        justify-content: flex-start;
            & > div{
                display: flex;
                justify-content: flex-start;
            }
            & > div:nth-of-type(1){
                padding-right: 25px;

                        & #projects-select {
                            font-size: 1.1rem;
                            font-weight: 700;
                            color: $base-font-Color;
                            padding-right: 20px;
                            &:active{
                                background-color: unset;
                                & > svg {
                                    fill: $primary-Blue;
                                }
                            }
                            &:focus{
                                background-color: unset;
                            }
                            
                        }
                        
                        & svg {
                            top: auto;
                        }
                    
                
            }
            & > div:nth-of-type(2){

                        & #epics-multiple-checkbox{
                            font-family: $primary-Font;
                            font-size: 1rem;
                            font-weight: 200;
                            color: $base-font-Color;
                            padding-right: 20px;
                            max-width: 200px;
                            min-height: auto;
                            &:focus{
                                background-color: unset;
                            }
                            &:active{
                                background-color: unset;
                            }
                        }
                        & svg{
                            top: auto;
                        }
                    
                
            }
        }
        & .kanbanBoardContainer{
            justify-content: center;
            overflow-x: hidden;
            overflow-y: hidden;
            & > div {
                display: flex;
                width: 100%;
                padding: 0;
                & > .react-kanban-board{
                    padding: 0;
                    overflow-y: unset!important;
                    justify-content: center;
                    & > div{
                        display: grid;
                        grid-auto-flow: column;
                        grid-auto-columns: 1fr;
                        width: 1280px;
                        & > .react-kanban-column{
                            border: none;
                            padding: 0px;
                            width: auto;
                            max-width: none;
                            position: relative;
                            margin: 10px;
                            &:nth-of-type(3), &:nth-of-type(5){
                                & > div:nth-of-type(1) > div > div > div{
                                    color: rgba( $base-font-Color, 0.3) !important;
                                }
                                & > div:nth-of-type(2){
                                    & > div {
                                        cursor: pointer;
                                    }
                                }
                            }
                            &::before{
                                content: "";
                                top: 50px;
                                left: -9.5px;
                                bottom: 0;
                                border-right: solid;
                                border-color: $base-light-grey;
                                border-width: 1px;
                                position: absolute;
                                z-index: 1;
                            }

                            & > div:nth-of-type(1){
                                & > div {
                                    justify-content: center;
                                    & > div {
                                        @media only screen and (max-width: 600px){
                                            justify-content: space-between;
                                        }
                                        & > div:nth-of-type(1){
                                            display: flex;
                                            justify-content: flex-start;
                                            text-transform: uppercase;
                                            color: $base-font-Color;
                                            font-size: 0.8rem;
                                            font-weight: 700;
                                            padding-bottom: 20px;
                                            @media only screen and (max-width: 600px){
                                                max-width: unset;
                                                flex-basis: unset;
                                            }
                                        }
                                        & > div:nth-of-type(2){
                                            display: flex;
                                            justify-content: flex-end;
                                            cursor: pointer;
                                            @media only screen and (max-width: 600px){
                                                max-width: unset;
                                                flex-basis: unset;
                                            }
                                            &:hover > svg > g > g {
                                                fill: rgba($base-font-Color, 0.2);
                                                transition: all 1s ease-out;
                                            }
                                            &:active > svg {
                                                transform: scale(0.9);
                                                transition: all 2s ease-out;
                                            }
                                            & > svg{
                                                padding: 0px;
                                            }
                                        }
                                    }
                                    & .droppableArea{
                                        height: -webkit-fill-available;
                                        width: 100%;
                                        display: none;
                                        background-color: rgba($base-font-Color, 0.2);
                                        position: absolute;
                                        left: -4px;
                                        border-radius: 20px;
                                    }
                                }
                            }

                            & > div:nth-of-type(2){
                                & > div{
                                    & > div{
                                        width: 100%;
                                        padding-bottom: 5px;
                                        & > div{
                                            box-shadow: none;
                                            background-color: $base-Color;
                                            border-radius: 10px;
                                            border: 1px solid $base-light-grey;
                                            width: 100%;

                                            
                                            & > div{
                                                & > div:nth-of-type(1){
                                                    margin-left: 15px;
                                                    margin-top: 15px;
                                                }
                                                & > div:nth-of-type(2){
                                                    & > div {
                                                        & > p {
                                                            font-family: $primary-Font;
                                                            word-wrap: break-word;
                                                            white-space: pre-wrap;
                                                            word-break: break-word;
                                                            color: $base-font-Color;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            -webkit-line-clamp: 2;
                                                            -webkit-box-orient: vertical;
                                                            display: -webkit-box;
                                                        }
                                                        & > p:nth-of-type(2){
                                                            font-size: 0.875rem;
                                                            color: rgba($base-font-Color, 0.7);
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            -webkit-line-clamp: 4;
                                                            -webkit-box-orient: vertical;
                                                            display: -webkit-box;
                                                        }
                                                    }
                                                }
                                                & > div:nth-of-type(3){
                                                    padding-left: 16px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    & > svg {
                                                        max-height: 30px;
                                                        width: auto;
                                                    }
                                                    & > span {
                                                        & > button {
                                                            & > svg {
                                                                & > path:nth-of-type(2), line{
                                                                    stroke: rgba($base-font-Color, 0.7);
                                                                }
                                                            
                                                            }
                                                        }
                                                        & > span{
                                                            right: 10px;
                                                            top: 10px;
                                                            background-color: $primary-orange;
                                                            outline: solid;
                                                            outline-width: 2px;
                                                            outline-color: $base-Color;
                                                            transition: all 0.2s ease-out;
                                                        }
                                                    }
                                                    & > .noBadge{
                                                        margin-right: 0;
                                                    }
                                                    & > .withBadge{
                                                        margin-right: 10px;
                                                    }
                                                } 
                                            }      
                                        }
                                    }
                                
                                }
                            }
                        
                        }
    
                        & > div.react-kanban-column:nth-of-type(1){
                            &::before{
                                content: "";
                                border-width: 0px;
                            }
                            
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
    

    .cards{
        animation-fill-mode: both;
    }

    .cards{
        transition: all 0.2s ease;
        &:hover{
            background-color: rgba( $base-font-Color, 0.1)!important;
        }
    }
}

.projectList, .epicsList{
    box-shadow: none !important;
    overflow: unset !important;
    top: 302px!important;
    -webkit-filter: drop-shadow(0px 0px 5px rgba( $base-font-Color, 0.5));
    max-width: 266px!important;
    width: 100%!important;
    border-radius: 10px!important;
    & > ul {
        color: unset;
        & > .dropDownBlob{
            position: absolute;
            right: 0px;
            left: 0px;
            top: -11px;
            margin: auto;
        }
        & > li{
            font-family: $primary-Font;
            background-color: unset;
            font-size: 0.875rem;
            color: $base-font-Color;
            padding: 11px 30px 10px 25px;
            white-space: normal;
            &:focus{
                background-color: unset;
            }
            &:nth-of-type(1){
                font-weight: 700;
                margin-bottom: 5px;
                border-bottom: solid 1px $base-light-grey;
            }

            & > span {
                background-color: unset;
            }

            & > span.MuiCheckbox-root {
                display: none;
            }

            &::before{
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 3px;
                border: solid 1px rgba( $base-font-Color, 0.3);
                margin-top: -1px;
                margin-right: 10px;
            }
            &:hover{
                background-color: rgba($primary-Blue, 0.1);
                transition: all 0.7s ease-out;
            }
        }
        & > li.Mui-selected{
            background-color: unset;
            cursor: auto;
            &::before{
                background-color: $primary-Blue;
                border: unset;
            }
            &::after{
                content: "";
                position: absolute;
                left: 22px;
                width: 16px;
                height: 16px;
                top: 13px;
                border-radius: 5px;
                background-color: rgba($primary-Blue, 0.1);
            }
            &:nth-of-type(1){
                border-bottom: solid 1px $base-light-grey;
            }
        }   
    }
}

.epicsList{
    right: 43.8rem !important;
    & > ul {
        & > li {
            & > div {
                & > span {
                    font-family: $primary-Font;
                    font-size: 0.875rem;
                    color: $base-font-Color;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px){
    #mainContainer{
        padding: 0px;
        padding-top: 8px;
    }
}

@media only screen and (max-width: 1470px){
    #board{
      & .kanbanBoardContainer{
            overflow-x: auto;
            overflow-y: hidden;

            & .react-kanban-board{
                justify-content: flex-start!important;
                & > div{
                    padding-right: 32px;
                }
            }
        }
    }

}