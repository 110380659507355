.confirmationContainer{
    & > div:nth-of-type(3){
        & > div {
            max-width: 400px;
        }
    }
    
    #confirm-dialog{
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        border-bottom: 1px solid rgba($base-font-Color, 0.1);

        & h2 {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 40px;
        }
        & p {
            padding: 20px 70px 20px 70px;
            color: $base-font-Color;
            text-align: center;
        }
    }

    & .dialogContentContainer{
        padding-top: 20px!important;
        padding-left: 70px!important;
        padding-right: 70px!important;
        padding-bottom: 20px;
        background-color: $base-Color;
        color: $base-font-Color;
        text-align: center;
    }

    & .dialogActionsContainer{
        background-color: $base-Color;
        padding-left: 24px;
        padding-bottom: 20px;
        width: -webkit-fill-available;
        justify-content: center;
    }

    & .deleteIconContainer{
        display: flex;
        position: relative;        
        z-index: 2;
        width: fit-content;
        padding: 5px;
        & .deleteIcon{
            fill: $primary-Blue;
        
        }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 90px;
        background-color: rgba($primary-Blue, 0.1);
        z-index: -1;
    }
}


    & .btn_yes{
        background-color: $primary-Blue;
        transition: all 0.2s ease;
        box-shadow: none;
        &:hover{
            filter: brightness(0.7);
            background-color: $primary-Blue;
        }
    }

    & .btn_no{
        background-color: $base-Color;
        color: $primary-Blue;
        border: 1px solid $primary-Blue;
        border-radius: 10px;
        transition: all 0.2s ease;
        box-shadow: none;
        &:hover{
            background-color: $primary-Blue;
            color: $base-Color;
        }
    }
}