@import "colors.scss";

#root{
    &>div{
        &>div{
            margin-top: 140px;
            @media only screen and (max-width: 900px){
                margin-top: 20px;
            }
        }
    }
}

#loginHeader{
    font-weight: 700;
}
#loginPage{

    & > div {
        & > div{
            @media only screen and (max-width: 900px){
                width: -webkit-fill-available;
            }
        }
    }

    & .login-form{
        @media only screen and (max-width: 900px){
            padding-left: 24px;
            padding-right: 24px;
        }
        & > div{
            @media only screen and (max-width: 900px){
                width: -webkit-fill-available;
            }
        }
    }



    .logo{
        @media only screen and (max-width: 900px){
            width: inherit;
        }
    }

    & form{
        &>div{
            &>div{
                
                &:nth-of-type(1){
                    &>div{
                        animation: bounceInLeft;
                        animation-duration: 1s;
                        animation-fill-mode: both;
                        
                    }
                }
    
                &:nth-of-type(2){
                    
                        animation: bounceInLeft;
                        animation-duration: 1s;
                        animation-delay: 0.2s;
                        animation-fill-mode: both;
                    
                }
                &:nth-of-type(3){
                    &>p{
                        animation: bounceInLeft;
                        animation-duration: 1s;
                        animation-delay: 0.4s;
                        animation-fill-mode: both;
                       
                        &>Button{
                            transform: scale(1);
                            transition: all 0.2s ease-in-out;
                            &:hover{
                                transform: scale(1.1);
                                transition: all 0.2s ease-in-out;
                                background-color: $base-hover-grey;
                            }
                        }
                      
                    }
                }
                &>div{
                    background-color: $base-light-grey;
                    transition: all 0.2s ease;
                    cursor: pointer;
                  
                    &:hover{
                        background-color: $base-hover-grey;           
                    }
                    &>div{
                        &>.MuiOutlinedInput-input{
                            padding-left: 25px;
                        }
                        &>.PrivateNotchedOutline-root-5{
                            border-width: 0;
    
                        }
                    }
                }
            }
        }
    }
}



.MuiOutlinedInput-root{
    border-radius: 90px !important;
}

#loginButton{
    text-transform: none;
    font-size: 1rem;
    font-weight: 700;
    color: $base-font-Color;
}

#webpack-dev-server-client-overlay{
    display: none!important;
}