#issuePopup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    min-width: 700px;
    background-color: #fff;
    border-radius: 10px;
    padding: 0px;
    max-height: 80%;
    overflow: auto;
    &>div:nth-of-type(1){
        align-items: flex-end;
        height: 70px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
        @media only screen and (max-width: 900px){
            padding-left: 32px;
            padding-right: 32px;
        }
        &>p{
            padding: 10px;
            display: flex;
            align-self: flex-end;
            color: $base-font-Color;
            font-weight: 700;
        }
        &>button{
            height: unset;
            padding: 10px;
        }
    }
    &>div:nth-of-type(2){
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 40% auto 20%;
        column-gap: 10px;
        padding-left: 50px;
        padding-right: 50px;
        width: auto;

        @media only screen and (max-width: 1440px){
            grid-auto-columns: 100%;
            grid-auto-flow: row;
            padding-left: 32px;
            padding-right: 32px;
        }


        &>div{
            width: auto;
            max-width: 300px;
            margin: 5px;

            @media only screen and (max-width: 1440px){
                max-width: unset;
            }
            &>p{
                font-family: $primary-Font;
                margin-bottom: 3px;
                color: $primary-Blue;
                font-weight: 700;
                font-size: 0.75rem;
                margin-left: 24px;
            }
            &>label{
                font-family: $primary-Font;
                color: rgba($base-font-Color, 0.5);
                font-weight: 400;
                font-size: 1rem;
                z-index: 1;
                top: 8px;
                left: 24px;
                transition: unset;
                text-overflow: ellipsis;
                pointer-events : none;
                &.Mui-focused{
                    font-weight: 700;
                    font-size: 0.875rem;
                    color: $primary-Blue;
                    display: none;
                    transition: unset;
                }
                &.MuiInputLabel-shrink{
                    color: $primary-Blue;
                    font-weight: 700;
                    transition: unset;
                    display: none;
                }
                &.Mui-disabled{
                    color: rgba($base-font-Color, 0.3);
                }
                // padding-bottom: 5px;
                // padding-left: 16px;
            }
            &>div{
                margin-top: 0;
                &.Mui-disabled{
                    &>svg{
                        &>path{
                            stroke: rgba($base-font-Color, 0.3);
                        }
                    }
                }
                &>div{
                    padding: 10px;
                    padding-left: 25px;
                    padding-right: 50px;
                    font-size: 1rem;
                    color: $base-font-Color;
                }
                &>svg{
                    margin-right: 25px;
                    top: auto;
                    & > path{
                        stroke: $base-font-Color;
                    }
                }
            }
        }
        &>div:nth-of-type(1){
            margin-left: 0;
            @media only screen and (max-width: 1440px){
                margin-left: unset;
            }

        }
        &>div:nth-of-type(3){
            margin-right: 0!important;  
            @media only screen and (max-width: 900px){
                margin-right: 5px;
            }
            &>div{
                &>div{
                    margin-right: 0;
                }
            }
        }

    }
    &>form{
        padding-left: 50px;
        padding-right: 50px;
        background-color: $base-Color;
        width: auto;
        border-top: 1px solid $base-light-grey;
        padding-top: 28px;
        margin-top: 15px;
        @media only screen and (max-width: 900px){
            padding-left: 32px;
            padding-right: 32px;
        }
        &>div{
            margin: 0;
            width: auto;
            &>div{
                padding: 0px;
                &>div{
                    background-color: unset;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    &>div{
                        margin-top: 0;


                        &>div{

                            & .dropzoneContainer{
                                & > span {
                                font-family: $primary-Font;
                                color: rgba($base-font-Color, 1);
                                padding-top: 5px;
                                font-size: 0.875rem;
                                font-weight: 200;
                                }
                            }

                            &.MuiDropzoneArea-root{
                                border-color: $base-light-grey;
                                background-color: unset;
                                &>div{
                                    &>svg{
                                        fill: $primary-Blue;
                                    }
                                    &>p{
                                        font-family: $primary-Font;
                                        color: $base-font-Color;
                                        font-size: 1rem;
                                    }
                                }
                            }

                            & .previewChip{
                                font-family: $primary-Font;
                                color: rgba($base-font-Color, 1);
                                font-size: 0.875rem;
                                font-weight: 200;
                            }

                            

                            .multiLine-textField{
                                border-radius: 20px!important;
                            }
                            border-radius: 20px;
                            margin-top: 0;
                            margin-bottom: 32px;
                            background-color: unset;
                            z-index: 5;


                            &.dropzoneHover{
                                min-height: unset;
                                z-index: 10;
                                border-width: 2px;
                                transition: all 0.2s ease;
                                margin-top: 10px;
                                & > div {
                                    padding-bottom: 30px;
                                }

                                &::before{
                                    border: 0px solid unset;
                                }
                                &:hover{
                                    border-color: rgba($primary-Blue, 1);
                                }
                            }

                            &.dropzoneHover:hover::before{
                                border: 1px solid rgba($base-font-Color, 0.0);
                                background-color: rgba($base-font-Color, 0.1);
                                border-radius: 10px;
                            }

                            &.MuiTextField-root{
                                border-radius: 90px;
                            }

                            &.multiLine-textField{
                                border-radius: 20px;
                            }

                            &>svg{
                                margin-right: 5px;
                                top: auto;
                            }
                            &>div{
                                padding: 10px;
                                padding-right: 24px;
                                padding-top: 5px;
                                padding-left: 0px;
                                // color: $base-font-Color;
                                margin-top: 0;
                                &>input{
                                    padding: 0;
                                }

                                & .timeIcon{
                                    fill: rgba($base-font-Color, 0.5);
                                }
                            }

                            &>div.MuiInput-underline{
                                &::before{
                                    border-bottom-color: rgba($base-font-Color, 0.1);
                                }
                                &:hover::before{
                                    border-bottom-color: rgba($primary-Blue, 1);
                                }
                                &::after{
                                    border-bottom-color: $primary-Blue;
                                }
                            }
                        }
                        & div.extrafieldsSelect{
                            &::before{
                                border-bottom-color: rgba($base-font-Color, 0.1);
                            }

                            &:hover::before{
                                border-bottom-color: rgba($primary-Blue, 1);
                            }

                            & > div{
                                background-color: unset;
                            }
                        }

                        &> .formTitles{
                            font-family: $primary-Font;
                            color: $base-font-Color;
                            font-weight: 700;
                            font-size: 0.75rem;
                            margin-left: 0px;
                            margin-bottom: 3px;
                            margin-top: 8px;
                        }
                        &>label{
                            font-family: $primary-Font;
                            color: $base-font-Color;
                            top: 12px;
                            left: 0px;
                            z-index: 10;
                            &.Mui-focused{
                                font-weight: 700;
                                font-size: 0.875rem;
                                color: $primary-Blue;
                                display: none;
                                transition: unset;
                            }
                            &.MuiInputLabel-shrink{
                                color: $primary-Blue;
                                font-weight: 700;
                                transition: unset;
                                display: none;
                            }
                            &.Mui-disabled{
                                color: rgba($base-font-Color, 0.3);
                            }
                        }
                        &>div{
                            &>p{
                                font-family: $primary-Font;
                                margin-bottom: 3px;
                                color: $primary-Blue;
                                font-weight: 700;
                                font-size: 0.875rem;
                                margin-left: 0px;
                            }
                            &>label{
                                font-family: $primary-Font;
                                color: $base-font-Color;
                                top: -17px;
                                left: 0px;
                                z-index: 1;
                                &.Mui-focused{
                                    font-weight: 700;
                                    font-size: 0.875rem;
                                    color: $primary-Blue;
                                    display: none;
                                    transition: unset;
                                }
                                &.MuiInputLabel-shrink{
                                    color: $primary-Blue;
                                    font-weight: 700;
                                    transition: unset;
                                    display: none;
                                }
                                &.Mui-disabled{
                                    color: rgba($base-font-Color, 0.3);
                                }
                            }
                            
                    
                        }

                        &.dropzoneContainer:hover::before{
                            border: 1px solid rgba($base-font-Color, 0.0)!important;
                        }

                        &.dropzoneContainer{
                            & > span {
                                font-family: $primary-Font;
                                color: $base-font-Color;
                                font-size: 0.75rem;
                                padding-left: 24px;
                            }

                            & div:nth-of-type(2){
                                & > div > div{
                                    & > span {
                                        color: $base-font-Color;
                                    }
                                }

                                &.MuiGrid-root .MuiDropzonePreviewList-imageContainer{
                                    padding-left: 24px;
                                }
                            }
                        }
                    }
                }
            }
            &>div:nth-of-type(1){
                margin-left: 0;
                padding-left: 0;
            }
            &>div:nth-of-type(2){
                margin-right: 0;
                padding-right: 0;
                &>div:nth-of-type(1){
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 20px;

                    @media only screen and (max-width: 1440px){
                        grid-template-columns: 1fr;
                    }
                    &>div{
                        margin-top: 0!important;
                        flex-basis: 50%;
                    }
                    &>.textareaMultiline{
                        grid-row: span 2;
                    }
                }
            }
            &>div:nth-of-type(3){
                &>p{
                    &>button{
                        &>span{
                            color: $base-font-Color;
                            text-transform: capitalize;
                        }
                        &:hover > span{
                            color: $primary-Blue;
                        }
                        &:active{
                            transform: scale(0.9);
                            transition: all 0.2s ease-out;
                        }
                    }
                }
            }
        }

        & .submitContainer{
            padding-bottom: 50px;
            & button{
                width: auto;
                padding: 5px 16px 5px 16px;
            }
            & span {
                color: $primary-Blue;
                padding: 5px 0px 5px 0px;
                text-transform: capitalize;
                justify-content: center;
            }
        }
    }
}

input:-internal-autofill-selected{
    background-color: unset!important;
    color: $base-font-Color!important;
}

.dropDownList{
    border-radius: 10px!important;
    &>ul{
        &>li{
            font-family: $primary-Font;
            background-color: unset;
            font-size: 0.875rem;
            color: $base-font-Color;
            padding: 11px 30px 10px 25px;
            white-space: normal;
            &:focus{
                background-color: unset;
            }
            &:hover{
                background-color: rgba($primary-Blue, 0.1);
                transition: all 0.7s ease-out;
            }
        }
    }
}


.alertContainer{
    & > div{
        padding-left: 10px;
        color: #ffffff!important;
        & span {
            fill: #ffffff!important;
            color: #ffffff!important;
            & svg {
                fill: #ffffff!important;
            }
        }
    }
}


//RESPONSIVE

@media only screen and (max-width: 900px){
    #issuePopup{
        min-width: unset;
        width: 90%;
    }
}