#issueDetail{
    padding-left: 32px;
    padding-right: 32px;
    overflow: hidden;
    background-color: $base-Color;
    width: 50%;
    animation-fill-mode: both;
    & >.issueContainer > div:nth-of-type(1){
        &>div{
            display: flex;
            flex-grow: 1;
            @media only screen and (max-width: 1200px){
                justify-content: center;
            }
                &>p{
                color: $base-font-Color;
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 90px;
                font-size: 1rem;
                background-color: $base-hover-grey;
                border: 1px solid rgba($base-font-Color, 0.5);
                &>button{
                    padding: 5px;
                }
            }
        }
        &>button:nth-of-type(1){
            margin-right: 5px;
        }
        &>button{
            padding: 0px;
        }
    }
    & .issueContainer{
        margin: 0;

        & > div:nth-of-type(1){
            padding-bottom: 10px;
        }

        @media only screen and (max-width: 1200px){
            height: -webkit-fill-available;
        }
        & > .contentContainer{
            padding-bottom: 0px;
            position: relative;
            display: flex;
            align-items: top;
            height: 600px;
            @media only screen and (max-width: 1200px){
                height: -webkit-fill-available;
            }
            &>form{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                height: -webkit-fill-available;
                position: relative;
                padding-right: 20px;
                overflow: hidden;
                &>div{
                    display: flex;
                    flex-wrap: wrap;
                    align-content: space-between;
                    row-gap: 20px;
                    @media only screen and (max-width: 1200px){
                        align-content: flex-start;
                        width: inherit;
                    }
                    &>div{
                        &>div{
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-bottom: 5px;
                            &>div{
                                color: $base-font-Color;
                                font-size: 1rem;
                                padding: 0px;
                                z-index: 2;
                                &>div{
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    &.MuiInput-underline:hover::before, &.MuiInput-underline:focus-within::before{
                                        border-bottom: 1px solid rgba($base-font-Color, 0.1);
                                    }
                                }

                                &:focus-within{
                                    background-color: unset;
                                }

                                &.Mui-disabled{
                                    color: rgba($base-font-Color, 0.5);
                                }
                                &>svg.Mui-disabled{
                                    display: none;
                                }
                                &>svg{
                                    top: auto;
                                    right: 10px;
                                }
                            }

                        }
                    }
                    &>div.extraFields{
                        row-gap: 32px;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-y: auto;
                        overflow-x: hidden;
                        max-height: 350px;
                        @media only screen and (max-width: 1200px){
                            padding-left: 21px;
                        }
                        & > div {
                            padding-right: 18px;
                        }
                    }

                    &>.extraFields-active{
                        max-height: 310px!important;
                    }
                }
            }

            .MuiInput-underline:before{
                border-bottom: 1px solid rgba($base-font-Color, 0.1);
            }
            .MuiInput-underline:after{
                border-bottom-color: rgba($primary-Blue, 1);
            }

            .MuiInputBase-root.MuiInput-underline:hover::before, .MuiInputBase-root.MuiInput-underline:focus-within::before{
                border-bottom: 2px solid rgba($primary-Blue, 1);
            }

            & p{
                margin-bottom: 5px;
            }

            & p, #subject{
                font-family: $primary-Font;
                color: $base-font-Color;
                -webkit-text-fill-color: $base-font-Color;
                font-size: 0.65rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            & #subject{
                font-size: 1.4rem;
                line-height: 1.5rem;
                padding-bottom: 0px;
                text-transform: none;
                margin-bottom: 0px;
            }

            & input, textarea{
                color: $base-font-Color;
                -webkit-text-fill-color: $base-font-Color;
                font-size: 1rem;
                padding: 0px;
                min-height: 19px;
            }

            & input.Mui-disabled, textarea.Mui-disabled{
                color: rgba($base-font-Color, 0.5);
                -webkit-text-fill-color: rgba($base-font-Color, 0.5);
            }

            & textarea.MuiInputBase-multiline{
                padding-top: 5px;
            }

            & textarea.Mui-disabled{
                padding: 0px;
            }

            & .textAreas{
                width: 100%;

            }

            & .textAreas:nth-of-type(1){
                padding: 18px;
                max-height: 260px;
                overflow-y: auto;
                overflow-x: hidden;
                @media only screen and (max-width: 1200px){
                    padding-left: 21px;
                    padding-right: 21px;
                }
            }

            & .textAreas-active{
                border-radius: 10px;
                &:hover, &:focus-within{
                    border-radius: 10px;
                    transition: all 0.2s ease;
                }
            }

            & .divider{
                position: relative;
                @media only screen and (max-width: 1200px){
                    padding-right: 0px;
                }
                &::after{
                    content: "";
                    width: 95%;
                    left: 0;
                    right: 0;
                    border-bottom: solid 1px $base-light-grey;
                    position: absolute;
                    bottom: 0;
                    @media only screen and (max-width: 1200px){
                        width: 100%;
                        margin: auto;
                    }
                }
            }

            & div.divider{
                z-index: 2;
                &::after{
                    top: 0;
                    border-radius: 10px;
                    border: solid 1px rgba($base-light-grey, 0.0);
                    border-bottom: solid 1px $base-light-grey;
                    z-index: -1;
                    width: 100%;
                }
                &:hover::after, &:focus-within::after{
                    border: solid 1px $base-light-grey;
                    transition: all 0.2s ease;
                }
                &.textAreas-disabled:hover::after, &.textAreas-disabled:focus-within::after{
                    border: solid 1px rgba($base-light-grey, 0.0);
                    border-bottom: solid 1px $base-light-grey;
                }
            }

            & .MuiInputBase-multiline{
                padding-top: 5px;
                padding-bottom: 5px;
            }

            & .textAreas-active > div:nth-of-type(2) > div > div {
                padding: 0px;
                & > textarea{
                    padding: 0px;
                }
            }

            & .MuiInputBase-multiline.Mui-disabled{
                padding: 0px;
            }

            & .MuiInputAdornment-root > svg{
                fill: rgba($base-font-Color, 0.5);
            }

            & .MuiInputBase-input{
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 20px;
            }

            & .MuiInputBase-input.Mui-disabled{
                padding: 0px;
                &:focus{
                    background-color: unset;
                }
            }

            & .dropzoneContainer{
                & > span {
                    font-family: $primary-Font;
                    color: rgba($base-font-Color, 1);
                    padding-top: 5px;
                    font-size: 0.875rem;
                    font-weight: 200;
                }

                & .MuiChip-label{
                    color: rgba($base-font-Color, 1);
                }
            }

            & .MuiDropzoneArea-root{
                background-color: unset;
                width: 100%;
                margin-bottom: 0px;
                & > div {

                        & > svg {
                            fill: $primary-Blue;
                        }
                    
                }
            }

            & .previewContainer{
                height: fit-content;
                justify-content: space-between;
                padding-right: 24px!important;
                padding-left: 5px;
                row-gap: 15px;
                & > div {
                    margin: 0px;
                    z-index: unset;
                    & > div{
                        position: relative;
                        padding: 0px;
                        max-height: 75px;
                        transition: 0.2s ease;
                        z-index: unset;

                        & > img {
                            border-radius: 5px;
                            transition: 0.2s ease;
                            border: 1px solid rgba($base-font-Color, 0.1);
                        }

                        & button {
                            position: absolute;
                            top: -10px;
                            right: -10px;
                            transition: 0.2s ease;
                            & svg {
                                z-index: 10;
                                background-color: rgba($primary-Blue, 1);
                                border-radius: 90px;
                                fill: $base-Color;
                                padding: 5px;
                                transform: scale(0.7);
                            }
                        }
                    }
                    
                }

                & > span{
                    display: none;
                }
                
            }

            & .submitButton{
                margin: 0;
                bottom: 0;
                padding-bottom: 40px;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                z-index: 10;
                & > p > button{
                    color: $base-Color;
                    -webkit-text-fill-color: $base-Color;
                    background-color: $primary-Blue;
                    margin: 0;
                }
            }

            & #description{
                max-height: 180px;
                font-size: 1.1rem;
                margin-top: 0px;
                padding-top: 0px;
                color: $base-font-Color;
                overflow-y: auto !important;
            }

            & .extraFields-disabled{
                & > .previewContainer{
                    padding-bottom: 40px;
                }
            }
        }
    }

    & .commentBase{
        padding: 24px;
        background-color: #ffffff;
        border: solid 0.5px $base-light-grey;
        border-radius: 10px;
        padding-right: 0px;
        display: flex;
        height: 600px;
        position: relative;
        @media only screen and (max-width: 1200px){
            height: -webkit-fill-available;
        }
        & #commentContainer{
            max-height: 600px;
            position: relative;
            @media only screen and (max-width: 1200px){
                max-height: unset;
            }
            & .chatOverviewArea{
                padding-right: 20px;
                padding-bottom: 40px;
                overflow-y: auto;
                height: -webkit-fill-available;

            
                .commentText{
                    &>button{
                        position: absolute;
                        padding: 0px;
                    }

                    word-break: break-word;
                }
                .commentText:nth-of-type(1){
                    position: relative;
                }
                .commentText:nth-of-type(2), .commentTextLeft:nth-of-type(2){
                    color: $base-font-Color;
                    font-size: 0.875rem;
                    margin: 0;
                    align-self: flex-end;
                }
                .commentTextLeft:nth-of-type(2){
                    align-self: flex-start;
                }
            }
            .accountIcon{
                max-height: 50px;
                max-width: 50px;
                &>svg{
                    width: auto;
                }
            }

                        .topFileContainer, .commentTextContainer{
                            display: flex;
                            flex-direction: column;
                            
                                
                                    & .mainFileContainer{
                                        margin: 0;
                                        & .fileContainer{
                                            width: 100%;
                                            & video, img, source{
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                                    }
                                
                            
                            
                        }
                
            
            &>div:nth-of-type(2){
                &>form{
                    margin-top: 0;
                    &>div{
                        flex-flow: row;
                        justify-content: space-between;
                        padding-right: 14px;
                        &>div{
                            position: relative;
                            width: 100%;
                            &>div:nth-of-type(1){
                                &>div.react-emoji{
                                    &>.react-input-emoji--container{
                                        margin: unset;
                                        background-color: $base-Color;
                                        &>.react-input-emoji--wrapper{
                                            &>.react-input-emoji--placeholder{
                                                left: 42px;
                                                width: calc(100% - 83px);
                                            }
                                            &>div:nth-of-type(2){
                                                padding-left: 42px;
                                                padding-right: 42px;
                                            }
                                        }
                                    }
                                    &>button{
                                        z-index: 1;
                                        position: absolute;
                                        right: 0;
                                        top: auto;
                                        max-height: 24px;
                                        &>svg.react-input-emoji--button--icon{
                                            fill: rgba($base-font-Color, 0.5);
                                        }
                                    }
                                }
                            }
                            &>div:nth-of-type(2){
                                position: absolute;
                                top: 7px;
                                left: 8px;
                                z-index: 1;
                                &>span{
                                    background-color: unset;
                                    &>button{
                                        border: unset;
                                        background-color: unset;
                                        transform: rotate(45deg);
                                        &>span{
                                            &>svg{
                                                fill: rgba($base-font-Color, 0.7);
                                            }
                                        }
                                    }
                                    &>span{
                                        transition: all 0.2s ease;
                                        top: -5%;
                                        left: -5%;
                                    }
                                }
                            }
                        }
                        &>div:nth-of-type(2){
                            max-width: 40px;
                            @media (min-width: 600px){
                                max-width: 40px;
                                flex-basis: 8.666667%;
                            }
                            &>p{
                                width: max-content;
                                &>button{
                                    min-width: 38px;
                                    border: unset;
                                    background-color: $base-Color;
                                    border-color: rgb(234, 234, 234);
                                    border-style: solid;
                                    border-width: 0.5px;
                                    &>span:nth-of-type(1){
                                        color: $base-font-Color;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .answerContainer{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding-right: 10px;
            }
        }
    }

    & .commentBase.unavailable{
        padding-right: 24px;
    }
}

.commentsUnavailable{
    color: $base-font-Color;
    margin: auto;
}

#loadIconContainer{
    display: flex;
    position: absolute;
    justify-content: center;
    left: 0;
    right: 0;
    padding-right: 20px;
}

#loadIcon{
    justify-content: center;
    justify-self: center;
    justify-items: center;
    align-content: center;
    align-self: center;
    align-items: center;
    margin: auto;
    width: 100%;
}


//RESPONSIVE

.tabLabelsContainer{
    height: auto!important;
    margin-top: 0px;

    & .MuiTabs-scroller{
        height: fit-content;
    }

    & .MuiTabs-flexContainer{
        background-color: #ffffff;
        width: 110px;
        border-radius: 10px;
        border: 1px solid rgba($base-font-Color, 0.1);
        margin: auto;
    }

    & button {
        font-weight: 700;
        text-transform: capitalize;
        min-height: 28px;
        padding: 0px;
        padding-top: 9px;
        padding-bottom: 8px;
        padding-left: 10px;
        z-index: 2;
        align-items: flex-start;
    }

    & button.Mui-selected{
        color: rgba($primary-Blue, 1);
    }

    & .MuiTabs-indicator{
        background-color: rgba($primary-Blue, 1);
        display: none;
    }
}

.tabContainer{
    height: calc(100% - 32px);
    & > div {
        padding: 0px;
        padding-bottom: 0px;
        height: -webkit-fill-available;
        p
        & form {
            padding-right: 0px!important;
            & .textAreas{
                height: fit-content;
            }
        }
    }
    
}

.tabContainer.chat{
    & > div {
        padding-bottom: 10px;
    }
}

.issueNavBar{
    padding-left: 16px;
    padding-right: 8px;
}



@media only screen and (max-width: 1200px){
    #issueDetail{
        width: -webkit-fill-available;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 32px;
        max-height: unset;
        height: -webkit-fill-available;
        border-radius: 0px;
    }

    #loadIconContainer{
        padding-right: 0px;
    }

    #menuDropdownMobile{
        z-index: 20;
        left: unset!important;
        right: 30px;
        top: 70px!important;
        transform: none!important;
        & > div {
            box-shadow: unset;
            filter: drop-shadow(0px 0px 6px rgba($base-font-Color, 0.1));
            background-color: unset;
        } 
    }
}


@media only screen and (max-width: 1440px){
    .textAreas{
        & > div {
            margin-bottom: 5px!important;
        }
    }

    #subject{
        line-height: 1.5rem;
        overflow: hidden;
    }
}
