#profileContainer{
    color: $base-font-Color;
    width: 30%;
    & .mainTitle{
        font-family: $primary-Font;
        align-self: center;
        padding-left: 28px;
        font-size: 1.3rem;
        font-weight: 700;
    }

    & .fallbackIcon{
        width: 100%;
        height: auto;
        stroke-width: 0.5px;
        transition: all 0.2s ease;
    }

    & .avatarContainer:hover .fallbackIcon{
        opacity: 0.7;
        transition: all 0.2s ease;
    }

    & .userContainer{
        padding: 20px 40px 0px 40px;
    }

    & .userInfoContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & .mainName{
        font-family: $primary-Font;
        font-weight: 700;
    }

    & .mainEmail{
        font-family: $primary-Font;
        font-weight: 200;
    }

    & .formInputs{

        &.formPasswordInput{
            margin-top: 20px;
        }
        
        & label{
            font-family: $primary-Font;
            color: $primary-Blue;
            font-weight: 700;
        }

        & input {
            font-family: $primary-Font;
            color: $base-font-Color;

            & .Mui-disabled{
                color: rgba($base-font-Color, 0.3);
            }
        }

        & #organisation{
            color: rgba($primary-Blue, 0.3);
        }

        & .passwordInput{
            margin-top: 20px;
            width: 100%;
            &::placeholder{
                color: rgba($base-font-Color, 0.3);
            }
        }

        & input.Mui-disabled{
            color: rgba($base-font-Color, 0.3);
        }
        
        & .MuiInput-underline:before{
            border-bottom-color: rgba($base-font-Color, 0.1);
        }

        & .MuiInput-underline:after{
            border-bottom-color: $primary-Blue;
        }

        & .passwordLabel{
            position: absolute;
            line-height: 1;
            transform: translate(0, 6.5px) scale(0.75);
        }
    }

    & .button-block{
        text-transform: capitalize;
        color: $base-font-Color;
        margin-top: 40px;
    }

    @media only screen and (max-width: 600px){
        text-align: center;
    }


    @media only screen and (max-width: 900px){
        width: 100%;
        max-height: unset;
        padding-top: 20px;
        height: -webkit-fill-available;

        .mainTitle{
            padding-left: 32px;
        }

        .closeButton{
            margin-right: 20px;
        }

        .userContainer{
            justify-content: center;
        }

        .submitButtonContainer{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 32px;
        }

        .submitButton{
            margin-top: 21px;
        }
    }

}

.MuiDialog-paperFullWidth.MuiPaper-elevation24{
    border-radius: 10px;
}

.MuiDialogTitle-root{
    padding: 0px!important;
}

.MuiDialogContent-root{
    padding-left: 32px!important;
    padding-right: 32px!important;
    padding-top: 42px!important;
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    & p {
        font-family: $primary-Font;
        color: $base-font-Color;
    }

    & svg {
        fill: $primary-Blue;
    }
}

.MuiDialogActions-root.MuiDialogActions-spacing{
    padding-right: 24px;
    padding-bottom: 16px;

    & button {
        border-radius: 20px;
        & span {
            text-transform: capitalize;
        }
    }
}